import React, { useState } from 'react';
import {
  ThemeProvider,
  TextField,
  CssBaseline,
} from '@mui/material';
import axios from 'axios';
import theme from './theme';
import GradientButton from './components/GradientButton';
import CodeBlock from './components/CodeBlock';
import SelectDropdown from './components/SelectDropdown';
import { languageData } from './config';
import { Helmet } from 'react-helmet';
import { MenuBar } from './components/MenuBar';
import './App.css'

function App() {
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedRunner, setSelectedRunner] = useState('');
  const [runnerOptions, setRunnerOptions] = useState([]);
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState('');

  const handleLanguageChange = (event) => {
    const language = event.target.value;
    setSelectedLanguage(language);
    const newRunners = languageData[language] || [];
    setRunnerOptions(newRunners);
    setSelectedRunner(newRunners[0] || ''); // Select the first runner by default
  };

  const handleRunnerChange = (event) => {
    setSelectedRunner(event.target.value);
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleGenerateClick = () => {
    setLoading(true);
    axios.post('https://unitester-bknd-6b548b3174d1.herokuapp.com/generateTest', { code, language: selectedLanguage, runner: selectedRunner })
      .then(response => {
        setResult(response.data.code);  // Extract the code property from the response data
      })
      .catch(error => {
        console.error('Error:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(result);
  };

  return (
    <div className="App">
      <Helmet>
        <title>Brew Test</title>
        <meta name="description" content="Easily generate unit test cases for your software projects. Improve the quality and reliability of your software." />
        <meta name="keywords" content="unit test, test case, generator, software testing, QA tools" />
        <meta name="author" content="Brew Test" />
        <meta property="og:title" content="Unit Test Case Generator" />
        <meta property="og:description" content="Easily generate unit test cases for your software projects. Improve the quality and reliability of your software." />
        <meta property="og:url" content="https://brewtest.co" />
        <meta name="ahrefs-site-verification" content="e61b28b772e4aba600429cbbabc6b6cba788702cf391ab1b29bec0046e76ab6f"></meta>
      </Helmet>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MenuBar></MenuBar>
      <div className='play-area'>
        <div className='hero-bg'>
          <div style={{position: 'absolute', bottom: '0', left: '0', opacity: '80%'}}>  
            <lottie-player src="https://lottie.host/571fd65a-57a4-4140-a62e-aeea398fcaaf/oen6AtfqiH.json" 
                           background="##FFFFFF" s
                           speed=".1" 
                           style={{width: "100%"}} 
                           loop 
                           autoplay 
                           direction="1" 
                           mode="normal">
            </lottie-player>
          </div>
          <div style={{color: '#fff',
                        fontWeight: 'bolder', 
                        zIndex: '999',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        lineHeight: '1',
                        textAlign: 'center'}}>
            <div style={{fontSize: '5rem'}}> Brew test </div>
            <div style={{fontSize: '2.5rem'}}> one stop to unit test </div>
          </div>
        </div>
        <div style={{padding: '2rem', width: '100%'}}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <SelectDropdown
              placeholder="Select Language"
              value={selectedLanguage}
              onChange={handleLanguageChange}
              options={Object.keys(languageData).map(language => ({ value: language, label: language }))}
            />
            <SelectDropdown
              placeholder="Select Runner"
              value={selectedRunner}
              onChange={handleRunnerChange}
              options={runnerOptions.map(runner => ({ value: runner, label: runner }))}
              disabled={!selectedLanguage} // Only active if a language is selected
            />
          </div>
          <div style={{display: 'flex', width: "100%", gap: "25px"}}>
            <div style={{flex: 1}}> 
              <TextField
                label="Your Code Here"
                multiline
                rows={14}
                fullWidth
                value={code}
                onChange={handleCodeChange}
                variant="filled"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.1)', // Subtle background
                  mt: 4,
                  mb: 2,
                  color: '#fff', // White text color
                  border: '1px solid rgba(255, 255, 255, 0.2)', // Subtle border
                  borderRadius: '4px', // Rounded corners
                  '& .MuiFilledInput-input': {
                    paddingLeft: '12px' // Text alignment
                  }
                }} />
            </div>
            {
            result && 
              <div style={{width: '50%', overflow: 'auto'}}>
                <CodeBlock code={result} onCopy={handleCopyClick} />
              </div>
            }
          </div>
         {code.trim() !== '' && <GradientButton
                variant="contained"
                onClick={handleGenerateClick}
                disabled={loading}
                style={{width: '100%', marginTop: '2rem'}}
                sx={{ alignSelf: 'flex-end', m: 'auto' }}
            >
                {
                    loading ? (
                        <span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                        </span>
                    ) : 'Generate'
                }
            </GradientButton>}
        </div>
      </div>
    </ThemeProvider>
    </div>
  );
}

export default App;
