import { Button, styled } from '@mui/material';

const GradientButton = styled(Button)({
  background: 'linear-gradient(45deg, #7048F9 30%, #4263EB 90%)', // Updated gradient colors
  border: 0,
  boxShadow: '0 3px 10px 2px rgba(66, 99, 235, .5)', // Adjusted shadow for a more pronounced effect
  color: 'white',
  height: 48,
  padding: '0 30px',
  borderRadius: '16px', // More rounded corners
  fontWeight: 'bold', // Bold text
  letterSpacing: '1px', // Spaced letters
  transition: 'transform 0.2s, box-shadow 0.2s', // Smooth transition for hover effect

  '&:hover': {
    transform: 'translateY(-3px)', // Lift effect on hover
    boxShadow: '0 5px 15px 2px rgba(66, 99, 235, .6)', // Deeper shadow on hover
  },
});

export default GradientButton;
