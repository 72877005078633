import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#4682B4', // Blue color from "Brew test"
    },
    secondary: {
      main: '#FF8C00', // Orange shade from the background
    },
    background: {
      default: '#121212', // Retaining dark background
      paper: '#1E1E1E', // Retaining this as it's a dark shade
    },
    text: {
      primary: '#FFFFFF', // Predominantly white text
      secondary: '#03DAC6', // This can be kept for accentuations or changed
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#4682B4',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FF8C00',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FF8C00',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '25px',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#2C2C2C',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#4682B4',
        },
      },
    },
  },
});

export default theme;
