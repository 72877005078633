import React, { Component } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import IconButton from '@mui/material/IconButton';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

const customStyle = {
  ...docco,
  'hljs': {
    ...docco.hljs,
    background: '#282c34',
    color: '#abb2bf'
  },
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div style={{ color: 'red' }}>Something went wrong.</div>;
    }
    return this.props.children;
  }
}

function CodeBlock({ code, onCopy }) {
  const handleCopyClick = () => {
    navigator.clipboard.writeText(code);
    if (onCopy) onCopy();
  };

  const parseCodeContent = () => {
    const lines = code.split('\n');
    const language = lines[0].replace('```', '').toLowerCase();
    const codeContent = lines.slice(2, -1).join('\n');
    return { language, codeContent };
  };

  const { language, codeContent } = parseCodeContent();

  return (
    <ErrorBoundary>
      <div
        style={{
          position: 'relative',
          marginTop: '2rem',
          border: '1px solid #ddd',
          borderRadius: '4px',
          width: '100%', 
          maxHeight: '355px', // Fixed width
          overflowX: 'auto'  // Enable horizontal scrolling
        }}
      >
        <SyntaxHighlighter language={language} style={customStyle} customStyle={{ margin: 0, borderRadius: '4px' }}>
          {codeContent}
        </SyntaxHighlighter>

        <IconButton
          color="primary"
          aria-label="Copy code"
          component="span"
          style={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
          onClick={handleCopyClick}
        >
          <FileCopyOutlinedIcon />
        </IconButton>
      </div>
    </ErrorBoundary>
  );
}

export default CodeBlock;

// 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