import Logo from '../assets/logo/logo.svg'
import './MenuBar.css'
import { PopupButton } from '@typeform/embed-react'

export const MenuBar = () => {
    return (
        <div style={{
            paddingTop: "2rem",
            display: "flex",
            maxWidth: '1020px',
            width: '80%',
            margin: 'auto',
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <div>
                <img src={Logo} alt="Logo" style={{ width: "100px"}}/>
            </div>
            <div style={{display: "flex"}}>
            <PopupButton size={60} id="JAhbHORw" className="menu__btn">
                Feedback ❤️ 
            </PopupButton>
            </div>
        </div>
    )
}