import React from 'react';
import { Select, MenuItem, Typography, Box } from '@mui/material';
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown';

function SelectDropdown({ value, onChange, options, placeholder, disabled }) {
  return (
    <Select
      value={value}
      onChange={onChange}
      variant="outlined"
      sx={{
        width: '49%',
        fontSize: '0.875rem',
        background: 'linear-gradient(45deg, #7048F9 30%, #4263EB 90%)',
        boxShadow:'0 3px 10px 2px rgba(66, 99, 235, .5)',
        color: '#fff', 
        border: '1px solid rgba(255, 255, 255, 0.2)', 
        borderRadius: '16px',
        '& .MuiSelect-icon': {
          color: 'transparent',
          '& svg': {
            fill: 'transparent',
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
          }
        },
        '& .MuiFilledInput-input': {
          paddingLeft: '12px', 
          paddingTop: '10px', 
          paddingBottom: '10px'
        }
      }}
      displayEmpty
      disabled={disabled}
      renderValue={(selected) => {
        if (selected === '') {
          return (
            <Typography style={{ fontWeight: 'bold', color: 'rgba(255, 255, 255, 0.6)' }}> 
              {placeholder}
            </Typography>
          );
        }
        return selected;
      }}
      IconComponent={() => (
        <Box sx={{ mr: 1 }}>
          <ChevronDownIcon />
        </Box>
      )}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value} sx={{ color: '#fff' }}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

export default SelectDropdown;
