export const languageData = {
    JavaScript: ['Jest', 'Mocha', 'Jasmine', 'QUnit', 'AVA', 'Tape'],
    Python: ['unittest', 'pytest', 'doctest', 'nose2', 'Hypothesis'],
    Java: ['JUnit', 'TestNG', 'Spock', 'Mockito', 'Arquillian'],
    Ruby: ['RSpec', 'Minitest', 'Test::Unit', 'Cucumber'],
    PHP: ['PHPUnit', 'PHPSpec', 'Behat', 'Codeception'],
    CSharp: ['NUnit', 'xUnit.net', 'MSTest', 'SpecFlow'],
    Go: ['Go testing package', 'Ginkgo', 'GoConvey'],
    TypeScript: ['Jest', 'Mocha', 'Jasmine', 'AVA', 'Tape'],
    Dart: ['test', 'mockito'],
    Kotlin: ['JUnit', 'Spek', 'Kotest'],
    Swift: ['XCTest', 'Quick', 'Nimble'],
    Rust: ['cargo test', 'RustyFork', 'stainless'],
    Scala: ['ScalaTest', 'Specs2', 'ScalaCheck'],
    Shell: ['Bats', 'shunit2', 'assert.sh'],
    R: ['testthat', 'RUnit', 'svUnit'],
    Angular: ['Jasmine', 'Karma', 'Protractor', 'Jest'],
    React: ['Jest', 'Enzyme', 'React Testing Library', 'Mocha'],
    Vue: ['Vue Test Utils', 'Jest', 'Mocha', 'Cypress'],
    // ... add more languages and frameworks as needed
  };
  